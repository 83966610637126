<template>
  <div>
    <div class="name">
      <input
        type="text"
        name="text"
        placeholder="请输入姓名"
        @input="updateValue($event.target.value)"
        ref="name"
        :value="objName"
        @blur="Verified"
      />
    </div>
    <p class="reg" :style="!realname ? 'height:14px' : ''">
      {{ realname ? "*请输入正确的姓名" : "" }}
    </p>
  </div>
</template>

<script>
export default {
  model: {
    prop: "objName",
    event: "input",
  },
  props: {
    objName: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      realname: false,
      username: "",
    //   mess: JSON.parse(sessionStorage.getItem("mess")),
    };
  },
  methods: {
    Verified(){
      !this.realname&&this.$emit('Verified')
    },
    updateValue(val) {
      this.realname = false;
      this.$emit("input", val.trim());
    },
  },
  mounted() {
    // this.$refs.name.value = this.mess.username;
  },
};
</script>

<style></style>
