// type == 'register' 注册页 // type == 'emenPwd' 修改密码页 // type == 'pwd' 忘记密码
<template>
  <div>
    <top
      :title="
        page_type == 'register'
          ? '新户领取'
          : page_type == 'emendPwd'
          ? '修改密码'
          : '忘记密码'
      "
    />
    <BackgroundBox
      :box_title="
        page_type == 'register'
          ? '新户领取'
          : page_type == 'emendPwd'
          ? '修改密码'
          : '忘记密码'
      "
      :nullFlag="nullFlag"
      @Onsubmit="Onsubmit"
      :btn="page_type == 'register' ? '提交验证' : '下一步'"
    >
      <template v-slot:headers>
        <UserPhoneField
          :inText="verobj.name"
          :type="false"
          v-if="page_type == 'emendPwd'"
        />
        <UserPhoneField
          :inText="verobj.phone"
          :type="false"
          v-if="page_type == 'emendPwd'"
        />

        <UserNameField v-model='verobj.name' v-if="page_type != 'emendPwd'" />
        <UserPhoneField v-model="verobj.phone" v-if="page_type != 'emendPwd'" />
        <CodeField v-model="verobj.code" @onCodeClick="onCodeClick" ref="codeField" />
      </template>
      <template v-slot:footer>
        <div class="radio" v-if="page_type == 'register'">
          <input
            type="radio"
            v-model="istrue"
            @click="istrue = !istrue"
            value="true"
            class="radio_sty"
          />
          <p>您已阅读并同意<span @click="Dialog">《理赔授权委托书》</span></p>
        </div>
      </template>
    </BackgroundBox>
  </div>
</template>

<script>
import BackgroundBox from "./components/box";
import UserNameField from "./components/username";
import UserPhoneField from "./components/userphone";
import CodeField from "./components/code";
import { checkInfo, forgotPwd  } from "@/api/_api/register";
export default {
  components: { BackgroundBox, UserNameField, UserPhoneField, CodeField },
  data() {
    return {
      page_type: "",
      verobj: {
        name: "",
        phone: "",
        code: "",
      },
      istrue: false,
        mess:JSON.parse(sessionStorage.getItem('mess'))
    };
  },
  mounted() {
    this.page_type = this.$route.query.type;
    if(this.page_type == "register"){
        this.verobj.name=this.mess.username
        this.verobj.phone=this.mess.phone
    }
    if (this.page_type == "emendPwd") {
      let { username } = JSON.parse(localStorage.getItem("user_data"));
      this.verobj.name = username.split("-")[0];
      this.verobj.phone = username.split("-")[1];
    }
  },
  methods: {
    //注册校验是否要填写身份证
    Onsubmit() {
      this.$toast.loading({ message: "加载中...", forbidClick: true });
      let type = this.$route.query.type;
      if (type == "register") {
        checkInfo(this.verobj).then((res) => {
          if (res.code == 200) {
            this.$store.commit("USER_INFO", this.verobj);
            if (res.data == true) {
              this.$router.push({ path: "/register", query: { type: "add", add: "true" } });
            } else {
              this.$router.push({
                path: "/register",
                query: { type: "setPwd", add: "true" },
              });
            }
          }
          this.$toast.clear();
        });
      } else {
        forgotPwd(this.verobj).then((res) => {
          if (res.code == 200 && res.data == true) {
            this.$store.commit("USER_INFO", this.verobj);
            this.$router.push({ path: "/register", query: { type: "setPwd" } });
          }
          this.$toast.clear();
        });
      }
    },
    onCodeClick() {
      if (!this.verobj.phone || !this.verobj.name) {
        this.$toast("请填写正确姓名，手机号");
      } else {
        let type = this.page_type == "register" ? "1" : "3";
        this.$refs.codeField.init(this.verobj, type);
      }
    },
    Dialog() {
      this.$dialog.alert({
        title: "授权委托书",
        message:
          "本人现委托受托人惠医保为本人保险合同的理赔事宜代理人，并同意其获得以下代理权限:\n1.办理理赔申请及受领理赔回退资料;\n2.受领理赔决定通知;\n3.签订理赔协议; \n4.受领给付款并签字。",
        confirmButtonColor: "#019cf4",
        theme: "round-button",
        messageAlign: "left",
      });
    },
  },
  computed: {
    nullFlag() {
      if (this.page_type == "register") {
        if (this.istrue && this.verobj.name && this.verobj.phone && this.verobj.code) {
          return false;
        }
      } else {
        if (this.verobj.name && this.verobj.phone && this.verobj.code) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style></style>
