var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('top',{attrs:{"title":_vm.page_type == 'register'
        ? '新户领取'
        : _vm.page_type == 'emendPwd'
        ? '修改密码'
        : '忘记密码'}}),_c('BackgroundBox',{attrs:{"box_title":_vm.page_type == 'register'
        ? '新户领取'
        : _vm.page_type == 'emendPwd'
        ? '修改密码'
        : '忘记密码',"nullFlag":_vm.nullFlag,"btn":_vm.page_type == 'register' ? '提交验证' : '下一步'},on:{"Onsubmit":_vm.Onsubmit},scopedSlots:_vm._u([{key:"headers",fn:function(){return [(_vm.page_type == 'emendPwd')?_c('UserPhoneField',{attrs:{"inText":_vm.verobj.name,"type":false}}):_vm._e(),(_vm.page_type == 'emendPwd')?_c('UserPhoneField',{attrs:{"inText":_vm.verobj.phone,"type":false}}):_vm._e(),(_vm.page_type != 'emendPwd')?_c('UserNameField',{model:{value:(_vm.verobj.name),callback:function ($$v) {_vm.$set(_vm.verobj, "name", $$v)},expression:"verobj.name"}}):_vm._e(),(_vm.page_type != 'emendPwd')?_c('UserPhoneField',{model:{value:(_vm.verobj.phone),callback:function ($$v) {_vm.$set(_vm.verobj, "phone", $$v)},expression:"verobj.phone"}}):_vm._e(),_c('CodeField',{ref:"codeField",on:{"onCodeClick":_vm.onCodeClick},model:{value:(_vm.verobj.code),callback:function ($$v) {_vm.$set(_vm.verobj, "code", $$v)},expression:"verobj.code"}})]},proxy:true},{key:"footer",fn:function(){return [(_vm.page_type == 'register')?_c('div',{staticClass:"radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.istrue),expression:"istrue"}],staticClass:"radio_sty",attrs:{"type":"radio","value":"true"},domProps:{"checked":_vm._q(_vm.istrue,"true")},on:{"click":function($event){_vm.istrue = !_vm.istrue},"change":function($event){_vm.istrue="true"}}}),_c('p',[_vm._v("您已阅读并同意"),_c('span',{on:{"click":_vm.Dialog}},[_vm._v("《理赔授权委托书》")])])]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }